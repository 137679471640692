import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _369dc52d = () => interopDefault(import('../pages/account.vue' /* webpackChunkName: "pages/account" */))
const _ce8a1420 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _0963473a = () => interopDefault(import('../pages/account/details/index.vue' /* webpackChunkName: "pages/account/details/index" */))
const _7bce3528 = () => interopDefault(import('../pages/account/funds.vue' /* webpackChunkName: "pages/account/funds" */))
const _62a05c86 = () => interopDefault(import('../pages/account/loyalities.vue' /* webpackChunkName: "pages/account/loyalities" */))
const _c279b9ea = () => interopDefault(import('../pages/account/newsletter.vue' /* webpackChunkName: "pages/account/newsletter" */))
const _0b971b6a = () => interopDefault(import('../pages/account/overview.vue' /* webpackChunkName: "pages/account/overview" */))
const _3d8254a0 = () => interopDefault(import('../pages/account/purchases.vue' /* webpackChunkName: "pages/account/purchases" */))
const _4cc459b2 = () => interopDefault(import('../pages/account/transactions.vue' /* webpackChunkName: "pages/account/transactions" */))
const _426a3497 = () => interopDefault(import('../pages/account/wishlist.vue' /* webpackChunkName: "pages/account/wishlist" */))
const _4863e81a = () => interopDefault(import('../pages/account/details/delivery.vue' /* webpackChunkName: "pages/account/details/delivery" */))
const _1ca836fe = () => interopDefault(import('../pages/account/details/invoice.vue' /* webpackChunkName: "pages/account/details/invoice" */))
const _7614010c = () => interopDefault(import('../pages/account/details/login.vue' /* webpackChunkName: "pages/account/details/login" */))
const _5aa0df7a = () => interopDefault(import('../pages/account/details/profile.vue' /* webpackChunkName: "pages/account/details/profile" */))
const _df6ce61c = () => interopDefault(import('../pages/account/details/store.vue' /* webpackChunkName: "pages/account/details/store" */))
const _e273ee8c = () => interopDefault(import('../pages/product/lister/actie.vue' /* webpackChunkName: "pages/product/lister/actie" */))
const _212d6b5a = () => interopDefault(import('../pages/cart/index.vue' /* webpackChunkName: "pages/cart/index" */))
const _94473a14 = () => interopDefault(import('../pages/checkout.vue' /* webpackChunkName: "pages/checkout" */))
const _26d74e0e = () => interopDefault(import('../pages/checkout/index.vue' /* webpackChunkName: "pages/checkout/index" */))
const _1b3abf9d = () => interopDefault(import('../pages/checkout/delivery.vue' /* webpackChunkName: "pages/checkout/delivery" */))
const _d38efdda = () => interopDefault(import('../pages/checkout/information.vue' /* webpackChunkName: "pages/checkout/information" */))
const _ce7f72bc = () => interopDefault(import('../pages/checkout/overview.vue' /* webpackChunkName: "pages/checkout/overview" */))
const _a81b7f26 = () => interopDefault(import('../pages/checkout/payment.vue' /* webpackChunkName: "pages/checkout/payment" */))
const _4e3c5a83 = () => interopDefault(import('../pages/checkout-redirect.vue' /* webpackChunkName: "pages/checkout-redirect" */))
const _a67285a8 = () => interopDefault(import('../pages/checkout-success.vue' /* webpackChunkName: "pages/checkout-success" */))
const _745f66ae = () => interopDefault(import('../pages/icon.vue' /* webpackChunkName: "pages/icon" */))
const _690755e4 = () => interopDefault(import('../pages/blog/category/index.vue' /* webpackChunkName: "pages/blog/category/index" */))
const _100e1a2c = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _1ce72e6c = () => interopDefault(import('../pages/brand/index.vue' /* webpackChunkName: "pages/brand/index" */))
const _06029aa8 = () => interopDefault(import('../pages/store/lister/index.vue' /* webpackChunkName: "pages/store/lister/index" */))
const _051147c8 = () => interopDefault(import('../pages/forgot-password/index.vue' /* webpackChunkName: "pages/forgot-password/index" */))
const _29612290 = () => interopDefault(import('../pages/wishlist/index.vue' /* webpackChunkName: "pages/wishlist/index" */))
const _2333b768 = () => interopDefault(import('../pages/blocks/demo.vue' /* webpackChunkName: "pages/blocks/demo" */))
const _a4589476 = () => interopDefault(import('../pages/forgot-password/reset.vue' /* webpackChunkName: "pages/forgot-password/reset" */))
const _645f48ce = () => interopDefault(import('../pages/faq/index.vue' /* webpackChunkName: "pages/faq/index" */))
const _0b3f2c32 = () => interopDefault(import('../pages/login/guest.vue' /* webpackChunkName: "pages/login/guest" */))
const _44565b54 = () => interopDefault(import('../pages/forgot-password/redirect.vue' /* webpackChunkName: "pages/forgot-password/redirect" */))
const _c38b46ce = () => interopDefault(import('../pages/blog/detail/_slug.vue' /* webpackChunkName: "pages/blog/detail/_slug" */))
const _61f19da3 = () => interopDefault(import('../pages/review/detail/_slug.vue' /* webpackChunkName: "pages/review/detail/_slug" */))
const _eedd7a60 = () => interopDefault(import('../pages/review/writing/_slug.vue' /* webpackChunkName: "pages/review/writing/_slug" */))
const _a572dfc8 = () => interopDefault(import('../pages/product/detail/_slug.vue' /* webpackChunkName: "pages/product/detail/_slug" */))
const _56da2c6a = () => interopDefault(import('../pages/store/detail/_slug.vue' /* webpackChunkName: "pages/store/detail/_slug" */))
const _018d6393 = () => interopDefault(import('../pages/search/_slug.vue' /* webpackChunkName: "pages/search/_slug" */))
const _8ca8dfca = () => interopDefault(import('../pages/blog/category/_.vue' /* webpackChunkName: "pages/blog/category/_" */))
const _0028f6ad = () => interopDefault(import('../pages/blog/tag/_.vue' /* webpackChunkName: "pages/blog/tag/_" */))
const _4e8017d3 = () => interopDefault(import('../pages/brand/lister/_.vue' /* webpackChunkName: "pages/brand/lister/_" */))
const _19610d2a = () => interopDefault(import('../pages/product/lister/_.vue' /* webpackChunkName: "pages/product/lister/_" */))
const _19982352 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _7151205f = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _369dc52d,
    children: [{
      path: "",
      component: _ce8a1420,
      name: "account___nl"
    }, {
      path: "details",
      component: _0963473a,
      name: "account-details___nl"
    }, {
      path: "funds",
      component: _7bce3528,
      name: "account-funds___nl"
    }, {
      path: "loyalities",
      component: _62a05c86,
      name: "account-loyalities___nl"
    }, {
      path: "newsletter",
      component: _c279b9ea,
      name: "account-newsletter___nl"
    }, {
      path: "overview",
      component: _0b971b6a,
      name: "account-overview___nl"
    }, {
      path: "purchases",
      component: _3d8254a0,
      name: "account-purchases___nl"
    }, {
      path: "transactions",
      component: _4cc459b2,
      name: "account-transactions___nl"
    }, {
      path: "wishlist",
      component: _426a3497,
      name: "account-wishlist___nl"
    }, {
      path: "details/delivery",
      component: _4863e81a,
      name: "account-details-delivery___nl"
    }, {
      path: "details/invoice",
      component: _1ca836fe,
      name: "account-details-invoice___nl"
    }, {
      path: "details/login",
      component: _7614010c,
      name: "account-details-login___nl"
    }, {
      path: "details/profile",
      component: _5aa0df7a,
      name: "account-details-profile___nl"
    }, {
      path: "details/store",
      component: _df6ce61c,
      name: "account-details-store___nl"
    }]
  }, {
    path: "/actie",
    component: _e273ee8c,
    name: "product-lister-actie___nl"
  }, {
    path: "/cart",
    component: _212d6b5a,
    name: "cart___nl"
  }, {
    path: "/checkout",
    component: _94473a14,
    children: [{
      path: "",
      component: _26d74e0e,
      name: "checkout___nl"
    }, {
      path: "delivery",
      component: _1b3abf9d,
      name: "checkout-delivery___nl"
    }, {
      path: "information",
      component: _d38efdda,
      name: "checkout-information___nl"
    }, {
      path: "overview",
      component: _ce7f72bc,
      name: "checkout-overview___nl"
    }, {
      path: "payment",
      component: _a81b7f26,
      name: "checkout-payment___nl"
    }]
  }, {
    path: "/checkout-redirect",
    component: _4e3c5a83,
    name: "checkout-redirect___nl"
  }, {
    path: "/checkout-success",
    component: _a67285a8,
    name: "checkout-success___nl"
  }, {
    path: "/icon",
    component: _745f66ae,
    name: "icon___nl"
  }, {
    path: "/inspiratie",
    component: _690755e4,
    name: "blog-category___nl"
  }, {
    path: "/login",
    component: _100e1a2c,
    name: "login___nl"
  }, {
    path: "/merk",
    component: _1ce72e6c,
    name: "brand___nl"
  }, {
    path: "/vestigingen",
    component: _06029aa8,
    name: "store-lister___nl"
  }, {
    path: "/wachtwoord-vergeten",
    component: _051147c8,
    name: "forgot-password___nl"
  }, {
    path: "/wishlist",
    component: _29612290,
    name: "wishlist___nl"
  }, {
    path: "/blocks/demo",
    component: _2333b768,
    name: "blocks-demo___nl"
  }, {
    path: "/forgot-password/reset",
    component: _a4589476,
    name: "forgot-password-reset___nl"
  }, {
    path: "/klantenservice/faq",
    component: _645f48ce,
    name: "faq___nl"
  }, {
    path: "/login/guest",
    component: _0b3f2c32,
    name: "login-guest___nl"
  }, {
    path: "/myaccount/resetpassword/redirect",
    component: _44565b54,
    name: "forgot-password-redirect___nl"
  }, {
    path: "/inspiratie/post/:slug?",
    component: _c38b46ce,
    name: "blog-detail-slug___nl"
  }, {
    path: "/review/detail/:slug?",
    component: _61f19da3,
    name: "review-detail-slug___nl"
  }, {
    path: "/review/writing/:slug?",
    component: _eedd7a60,
    name: "review-writing-slug___nl"
  }, {
    path: "/p/:slug?",
    component: _a572dfc8,
    name: "product-detail-slug___nl"
  }, {
    path: "/vestigingen/:slug?",
    component: _56da2c6a,
    name: "store-detail-slug___nl"
  }, {
    path: "/zoek/:slug?",
    component: _018d6393,
    name: "search-slug___nl"
  }, {
    path: "/inspiratie/*",
    component: _8ca8dfca,
    name: "blog-category-all___nl"
  }, {
    path: "/tag/*",
    component: _0028f6ad,
    name: "blog-tag-all___nl"
  }, {
    path: "/m/*",
    component: _4e8017d3,
    name: "brand-lister-all___nl"
  }, {
    path: "/producten/*",
    component: _19610d2a,
    name: "product-lister-all___nl"
  }, {
    path: "/",
    component: _19982352,
    name: "index___nl"
  }, {
    path: "/*",
    component: _7151205f,
    name: "all___nl"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
